import HeroSection from './components/HeroSection/HeroSection';
import { ThemeProvider } from './contexts/ThemeContext';

const App = () => {
    return (
        <div id='main'>
            <ThemeProvider>
                <HeroSection />
            </ThemeProvider>
        </div>
    )
}

export default App;