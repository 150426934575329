import React, { useContext, useState } from 'react';

export const palette = [
    // Orange on Green
    {
        fog: '#8DFFA4',
        primary: '#F49B14',
        secondary: '#FFF653',
    },
    // Purple on Yellow
    {
        fog: '#FFF067',
        primary: '#7743DB',
        secondary: '#6E62FF',
    },
    // Light Blue on Pink
    {
        fog: '#FF5B9B',
        primary: '#8393FF',
        secondary: '#CEA5FF',
    },
    // Lava
    {
        fog: '#ffedde',
        primary: '#E72A2D',
        secondary: '#FF8A00',
    },
    // Green on Blue
    {
        fog: '#0021FF',
        primary: '#008080',
        secondary: '#A1F3B1',
    },
    // Ocean
    {
        fog: '#e4f4f5',
        primary: '#0000ff',
        secondary: '#7fffd4',
    },
    // Orange on Purple
    {
        fog: '#7400AC',
        primary: '#FF0000',
        secondary: '#FFC853',
    },
    // Pink on Blue
    {
        fog: '#003AFF',
        primary: '#FF0366',
        secondary: '#FF5497',
    },
    // Slate 
    {
        fog: '#fafafa',
        primary: '#000000',
        secondary: '#808080',
    },
    // Red on Yellow 
    {
        fog: '#E9B44C',
        primary: '#9B2915',
        secondary: '#9B2915',
    },
]

export const PALLETE_INIT_INDEX = Math.floor(Math.random() * palette.length);

export const incrementPaletteIndex = (paletteIndex) => {
    return (paletteIndex + 1) % palette.length;
}

const ThemeContext = React.createContext();

export function useTheme() {
	return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
	const [paletteIndex, setPaletteIndex] = useState(PALLETE_INIT_INDEX);
	const [currentPalette, setCurrentPalette] = useState(palette[PALLETE_INIT_INDEX]);

	const changePalette = () => {
        setPaletteIndex((paletteIndex + 1) % palette.length);
		setCurrentPalette(palette[(paletteIndex + 1) % palette.length]);
	}
	
	const value = {
        currentPalette,
		changePalette,
	}

	return (
		<ThemeContext.Provider value={value}>
			{children}
		</ThemeContext.Provider>
	)
}