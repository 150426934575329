import { useMemo, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { useTheme } from '../../contexts/ThemeContext';
import HeroOverlay from './HeroOverlay';
import CursorHint from '../../CursorHint';
import Scene from './Scene';

const HeroSection = () => {
    const sceneRef = useRef();
    const [isMouseDown, setIsMouseDown] = useState();
    const { changePalette } = useTheme();
    const threeScene = useMemo(() => <Canvas><Scene ref={sceneRef} /></Canvas>, []); // Memoize the canvas so it does not rerender/reset on state change

    const updateHero = () => {
        sceneRef.current.updateScene();
        changePalette();
    }

    return (
        <div
            className='w-full h-screen cursor-none'
            onClick={updateHero}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseUp={() => setIsMouseDown(false)}
        >
            <CursorHint isMouseDown={isMouseDown} />
            <HeroOverlay />
            {threeScene}
        </div>
    )
}

export default HeroSection;