import { useEffect } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import { useTheme } from './contexts/ThemeContext';

const CursorHint = ({ isMouseDown }) => {
    const { currentPalette } = useTheme();

    const cursorX = useMotionValue(-100);
    const cursorY = useMotionValue(-100);

    const springConfig = { damping: 100, stiffness: 400 };
    const cursorXSpring = useSpring(cursorX, springConfig);
    const cursorYSpring = useSpring(cursorY, springConfig);

    useEffect(() => {
        const moveCursor = (e) => {
            cursorX.set(e.clientX - 40);
            cursorY.set(e.clientY - 40);
        };

        window.addEventListener('mousemove', moveCursor);
        return () => window.removeEventListener('mousemove', moveCursor);
    });

    return (
        <motion.div
            className='
                font-montserrat font-bold tracking-widest
                rounded-full w-20 h-20 z-20 
                fixed select-none pointer-events-none flex justify-center items-center
            '
            style={{
                translateX: cursorXSpring,
                translateY: cursorYSpring,
            }}
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
                backgroundColor: currentPalette.primary,
                scale: isMouseDown ? 1.2 : 1,
            }}
            transition={{
                opacity: { duration: 1, delay: 2 },
                backgroundColor: { duration: 1 },
                scale: { type: "spring", damping: 15, stiffness: 700 }
            }}
        >
            <motion.span
                animate={{
                    color: currentPalette.fog,
                }}
            >
                CLICK
            </motion.span>
        </motion.div>
    )
}

export default CursorHint;