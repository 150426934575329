import { motion } from 'framer-motion';

const HeroOverlay = () => {
    return (
        <div className='
            fixed w-full h-full  
            select-none pointer-events-none z-10
            flex justify-between
            m-auto lg:p-16
        '>
            <motion.div
                className='hidden lg:flex flex-col items-center h-full space-y-4'
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                transition={{ duration: 1.5, delay: 2.6 }}
            >
                <div className='line grow w-px bg-black' />
                <span className='font-montserrat text-sm font-semibold tracking-widest [writing-mode:vertical-lr]'>WEBSITE 2.0 IN DEVELOPMENT</span>
                <div className='dot w-2 h-2 rounded-full bg-black' />
            </motion.div>
            <div className='header-desktop hidden lg:flex text-center whitespace-pre flex-col justify-center m-auto'>
                <div className='overflow-hidden'>
                    <motion.h1
                        className='font-humane [font-size:32vw] [line-height:0.8] [margin-top:2vw]'
                        initial={{ y: '110%' }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.7, delay: 2, ease: 'easeOut' }}
                    >
                        MICHAEL  PRELICH
                    </motion.h1>
                </div>
                <div className='overflow-hidden'>
                    <motion.h2
                        className='font-montserrat [font-size:1.6vw] tracking-widest'
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5, delay: 2.6, ease: 'easeOut' }}
                    >
                        PRODUCT DESIGNER  &  CREATIVE DEVELOPER
                    </motion.h2>
                </div>
            </div>
            <div className='header-mobile flex lg:hidden text-center whitespace-pre-wrap flex-col justify-center m-auto'>
                <div className='overflow-hidden'>
                    <motion.h1
                        className='font-humane [font-size:48vw] [line-height:0.9] [margin-top:2vw]'
                        initial={{ y: '110%' }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.7, delay: 2, ease: 'easeOut' }}
                    >
                        MICHAEL
                        <br/>
                        PRELICH
                    </motion.h1>
                </div>
                <div className='overflow-hidden'>
                    <motion.h2
                        className='font-montserrat [font-size:3vw] [line-height:2] tracking-widest'
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5, delay: 2.6, ease: 'easeOut' }}
                    >
                        PRODUCT DESIGNER
                        <br/>
                        CREATIVE DEVELOPER
                    </motion.h2>
                </div>
            </div>
            <motion.div
                className='hidden lg:flex flex-col items-center h-full space-y-4'
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                transition={{ duration: 1.5, delay: 2.6 }}
            >
                <div className='dot w-2 h-2 rounded-full bg-black' />
                <span className='font-montserrat text-sm font-semibold tracking-widest [writing-mode:vertical-lr]'>WEBSITE 2.0 IN DEVELOPMENT</span>
                <div className='line grow w-px bg-black' />
            </motion.div>
        </div>
    )
}

export default HeroOverlay;