import { forwardRef, useEffect, useRef, useImperativeHandle } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import { EffectComposer, DepthOfField, Noise } from "@react-three/postprocessing";
import { Depth, LayerMaterial } from 'lamina/vanilla';
import { createNoise3D } from 'simplex-noise';
import { easing } from 'maath';
import { palette, incrementPaletteIndex, PALLETE_INIT_INDEX } from '../../contexts/ThemeContext';

const diskMaterial = new LayerMaterial({
    lighting: 'physical',
    side: THREE.DoubleSide,
    layers: [
        new Depth({
            near: 0.5,
            far: 1,
        }),
    ]
});

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const Scene = forwardRef((props, ref) => {
    const fogRef = useRef();
    const bgRef = useRef();

    // World Variables
    let paletteIndex = PALLETE_INIT_INDEX;
    let cameraPosition = new THREE.Vector3(0, 0, 10);
    let gimbleRotation = new THREE.Euler(0, 0, 0, 'XYZ');
    let fogNear = cameraPosition.z - 1;
    let fogFar = THREE.MathUtils.randFloat(cameraPosition.z - 0.5, cameraPosition.z + 0.5);

    // Sphere Variables
    const minDisks = 6;
    const maxDisks = 24;
    let speed = THREE.MathUtils.randFloat(0.2, 0.4);
    let numDisks = maxDisks;

    useImperativeHandle(ref, () => ({
        async updateScene() {
            const newCamZ = THREE.MathUtils.randFloat(1.8, 2.8);
            cameraPosition.set(0, 0, 10);

            await delay(200);

            gimbleRotation.set(
                THREE.MathUtils.randFloat(-1, 1) * Math.PI,
                THREE.MathUtils.randFloat(-1, 1) * Math.PI / 4,
                THREE.MathUtils.randFloat(-1, 1) * Math.PI,
                'XYZ'
            );
            paletteIndex = incrementPaletteIndex(paletteIndex);
            fogFar = THREE.MathUtils.randFloat(newCamZ - 0.5, newCamZ + 0.25);
            fogNear = fogFar - 1.1;
            speed = THREE.MathUtils.randFloat(0.2, 0.4);
            numDisks = THREE.MathUtils.randInt(minDisks, maxDisks);

            cameraPosition.set(0, 0, newCamZ);
        }
    }));

    useFrame((state, delta) => {
        easing.dampC(fogRef.current.color, palette[paletteIndex].fog, 0.2, delta);
        easing.damp(fogRef.current, 'near', fogNear, 0.4, delta);
        easing.damp(fogRef.current, 'far', fogFar, 0.4, delta);
        easing.dampC(bgRef.current, palette[paletteIndex].fog, 0.2, delta);
    });

    const CameraGimble = () => {
        const cameraRef = useRef();
        const cameraGroup = useRef();
        const gimbleGroup = useRef();

        useFrame((state, delta) => {
            easing.dampE(cameraGroup.current.rotation, [0, -state.pointer.x * Math.PI / 12, 0], 0.4, delta);
            easing.damp3(cameraGroup.current.position, [0, 0, -state.pointer.y / 5,], 0.4, delta);
            easing.damp3(cameraRef.current.position, cameraPosition, 0.8, delta);
            easing.dampE(gimbleGroup.current.rotation, gimbleRotation, 0.4, delta);
        });

        useEffect(() => {
            cameraPosition.set(0, 0, THREE.MathUtils.randFloat(2, 2.5));
            fogNear = cameraPosition.z - 1;
            fogFar = THREE.MathUtils.randFloat(cameraPosition.z - 0.5, cameraPosition.z + 0.5);
            gimbleRotation.set(
                THREE.MathUtils.randFloat(-1, 1) * Math.PI,
                THREE.MathUtils.randFloat(-1, 1) * Math.PI / 4,
                THREE.MathUtils.randFloat(-1, 1) * Math.PI,
                'XYZ'
            );
        }, [])

        return (
            <group ref={gimbleGroup} rotation={gimbleRotation} >
                <group ref={cameraGroup} >
                    <PerspectiveCamera
                        ref={cameraRef}
                        makeDefault
                        position={[0, 0, 10]}
                        fov={40}
                        far={10}
                    />
                </group>
            </group>
        );
    }

    const noise3D = createNoise3D();
    const Disk = ({ index }) => {
        const ref = useRef();

        useEffect(() => {
            // const geometry = ref.current.geometry;
            // let pos = geometry.attributes.position;

            // // Twist along y axis
            // for (let i = 0; i < pos.count; i++) {
            //     const direction = new THREE.Vector3(0, 1, 0);
            //     const vertex = new THREE.Vector3();

            //     const x = pos.getX(i);
            //     const y = pos.getY(i);
            //     const z = pos.getZ(i);

            //     vertex.set(x, y, z);
            //     vertex.applyAxisAngle(direction, Math.PI * y / 8)
            //     pos.setXYZ(i, vertex.x, vertex.y, vertex.z)
            // }
            // pos.needsUpdate = true;
        })

        useFrame((state, delta) => {
            ref.current.visible = index < numDisks;
            if (!ref.current.visible) return;

            const geometry = ref.current.geometry;
            const xPosFactor = 0.05 * (1 - Math.abs(ref.current.position.x));
            const multiplier = 3;

            let pos = geometry.attributes.position;
            let uv = geometry.attributes.uv;
            let vec2 = new THREE.Vector2();
            let time = performance.now() * 0.001;

            // Distort each vertex of the disk
            for (let i = 0; i < pos.count; i++) {
                // Distort along planar z axis
                vec2.fromBufferAttribute(uv, i).multiplyScalar(multiplier);

                const z = THREE.MathUtils.lerp(
                    pos.getZ(i),
                    noise3D(vec2.x, vec2.y, index + time) * xPosFactor,
                    0.05
                );
                pos.setZ(i, z);

                // Distort along planar x/y axis
                vec2.fromBufferAttribute(pos, i);
                const angle = vec2.angle();
                const distortion = noise3D(Math.cos(angle), Math.sin(angle), time * 0.1); // Sample noise in a circle so it loops
                vec2.normalize().multiplyScalar(1 + distortion * 0.1);
                pos.setXY(i, vec2.x, vec2.y)
            }
            pos.needsUpdate = true;

            // Scale disks using unit circle equation
            ref.current.scale.x = ref.current.scale.y = Math.pow(1 - Math.pow(ref.current.position.x, 2), 0.5);

            // Translate disks along world x axis using cosine
            const spacing = (index / numDisks) * Math.PI;
            const trigTime = ((speed * time) + spacing) % Math.PI;
            ref.current.position.x = Math.cos(trigTime);

            // Update colors
            ref.current.material.layers[0].colorB.set(palette[paletteIndex].primary);
            ref.current.material.layers[0].colorA.set(palette[paletteIndex].secondary);
        })

        return (
            <mesh
                ref={ref}
                position={[10, 0, 0]}   // start the disks off screen for initial render
                rotation={[0, Math.PI / 2, 0]}
                material={diskMaterial}
            >
                <circleGeometry args={[1, 128]} />
            </mesh>
        )
    }

    const PlanarSphere = () => {
        return (
            Array.from({ length: maxDisks }, (_, i) => (
                <Disk
                    key={i}
                    name={'disk-' + (i + 1)}
                    index={i}
                />
            ))
        );
    }

    return (
        <>
            <fog ref={fogRef} attach="fog" args={[palette[paletteIndex].fog, fogNear, fogFar]} />
            <color ref={bgRef} attach="background" args={[palette[paletteIndex].fog]} />

            <CameraGimble />

            <ambientLight intensity={0.5} />
            <spotLight position={[5, 5, 5]} angle={0.4} penumbra={1} />
            <spotLight position={[-5, 5, 5]} angle={0.4} penumbra={1} />

            <PlanarSphere />

            <EffectComposer>
                <DepthOfField
                    focusDistance={0.15}
                    focalLength={0.05}
                    bokehScale={20}
                    height={480}
                />
                <Noise opacity={0.4} />
            </EffectComposer>
        </>
    );
})

export default Scene;